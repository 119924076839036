<template>
<div class="mobile-page-layout">

    <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
                
                <router-link v-if="listShow" :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>

                <div v-if="!listShow && sidebarOptions != 'options' && sidebarOptions != 'edit'" class="mobile-page-header-icon pointer back-icon" @click.prevent="listShow = !listShow">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </div>

                <div v-if="!listShow && sidebarOptions != 'options' && sidebarOptions == 'edit'" class="mobile-page-header-icon pointer back-icon" @click.prevent="sidebarOptions = null">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </div>

                <div v-if="!listShow && sidebarOptions == 'options' && sidebarOptions != 'edit'" class="mobile-page-header-icon pointer back-icon" @click.prevent="sidebarOptions = null">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </div>

                <div class="mobile-page-header-icon">
                    <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="fa-primary" d="M256 64c0-35.35-28.65-64-64-64S128 28.65 128 64C92.65 64 64 92.65 64 128v16C64 152.8 71.16 160 80 160h224C312.8 160 320 152.8 320 144V128C320 92.65 291.3 64 256 64zM192 88C178.7 88 168 77.25 168 64c0-13.26 10.75-24 24-24S216 50.74 216 64C216 77.25 205.3 88 192 88zM96 376c-13.25 0-24 10.75-24 24S82.75 424 96 424s24-10.75 24-24S109.3 376 96 376zM164.7 228.7L112 281.4L91.31 260.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62l32 32C103.8 318.4 107.9 320 112 320s8.188-1.562 11.31-4.688l64-64c6.25-6.25 6.25-16.38 0-22.62S170.9 222.4 164.7 228.7z" />
                        <path class="fa-secondary" d="M336 64H256c35.35 0 64 28.65 64 64v16C320 152.8 312.8 160 304 160h-224C71.16 160 64 152.8 64 144V128c0-35.35 28.65-64 64-64H48C21.49 64 0 85.49 0 112v352C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48v-352C384 85.49 362.5 64 336 64zM96 424c-13.25 0-24-10.75-24-24S82.75 376 96 376s24 10.75 24 24S109.3 424 96 424zM112 320c-4.094 0-8.188-1.562-11.31-4.688l-32-32c-6.25-6.25-6.25-16.38 0-22.62s16.38-6.25 22.62 0L112 281.4l52.69-52.69c6.25-6.25 16.38-6.25 22.62 0s6.25 16.38 0 22.62l-64 64C120.2 318.4 116.1 320 112 320zM304 416h-128C167.2 416 160 408.8 160 400C160 391.2 167.2 384 176 384h128c8.801 0 16 7.199 16 16C320 408.8 312.8 416 304 416zM304 320h-96C199.2 320 192 312.8 192 304C192 295.2 199.2 288 208 288h96C312.8 288 320 295.2 320 304C320 312.8 312.8 320 304 320z" />
                    </svg>
                </div>
                <div class="mobile-page-header-text">
                    <slot v-if="sidebarOptions != 'options'">
                        <slot v-if="listShow">وظایف روزانه</slot>
                        <slot v-if="!listShow">{{ activeTodo.title}}</slot>
                    </slot>
                    <slot v-else>
                        تنظیمات وظایف روزانه
                    </slot>
                </div>  
            </div>
            <div class="mobile-page-header-actions">


                <div v-if="sidebarOptions != 'options' && sidebarOptions != 'edit'" class="mobile-page-header-icon pointer" @click="modal = !modal">
                    <i class="header-uil-icon uil uil-plus-circle"></i>
                </div>

                <div v-if="!listShow && sidebarOptions != 'options' && sidebarOptions != 'edit'" class="mobile-page-header-icon pointer" @click.prevent="sidebarOptions = 'options'">
                    <i class="header-uil-icon uil uil-setting"></i>
                </div>

                <div v-if="!listShow && sidebarOptions != 'options' && sidebarOptions == 'edit'" class="mobile-page-header-icon pointer" @click.prevent="removeTodoItem(activeTodoItem.id)">
                    <i class="header-uil-icon uil uil-trash table-icons red-color" style="color: rgb(235, 70, 84); font-size: 24px; padding: 3px;"></i>
                </div>

                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list class="header-help-list">
                                <v-list-item @click.prevent="get_modal('search')" v-if="listShow && sidebarOptions != 'options' && sidebarOptions != 'edit'">
                                    <v-list-item-title>جستجو</v-list-item-title>
                                </v-list-item>
                                
                                <v-list-item @click.prevent="get_modal('search2')" v-if="!listShow && sidebarOptions != 'options' && sidebarOptions != 'edit'">
                                    <v-list-item-title>جستجو</v-list-item-title>
                                </v-list-item>
                                
                                <v-list-item @click.prevent="get_modal('help')">
                                    <v-list-item-title>راهنما</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div>

    <slot v-if="widgetLoad">
        <div class="tgju-widget-content full-padding">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
    </slot>
    <slot v-else>

        <!-- صفحه فهرست -->
        <slot  v-if="listShow && sidebarOptions != 'options' && sidebarOptions != 'edit'">

            <div v-if="todos.length && search" class="mobile-search-box">
                <v-text-field class="mobile-app-input mobile-app-input-full" label="جستجو در فهرست وظایف" id="newFavorite" v-model="searchQuery"></v-text-field>
            </div>

            <div v-if="todos.length == 0" class="noWidget noContent">
                <div>
                    <i class="uil uil-clipboard-notes noContent-icon" aria-hidden="true"></i>
                    <p class="noContent-title">فهرست وظایفی برای نمایش وجود ندارد</p>
                    <div class="tgju-btn  m-top tgju-btn-primary" @click="modal = !modal"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد فهرست</div>
                </div>
            </div>

            <table  v-if="todos.length != 0" class="widget-table widget-table-lg">
                <thead>
                    <tr>
                        <th>عنوان</th>
                        <th class="w60 text-center">ویرایش</th>
                        <th class="w60 text-center">حذف</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" >
                        <td class="border-l bg-gray" @click="showStarsTodo()"><i class="uil uil-star"></i> منتخب‌ها</td>
                        <td class="w60 text-center border-l disabled"><i class="uil uil-edit-alt table-icons pointer"></i></td>
                        <td class="w60 text-center disabled"><i class="uil uil-trash table-icons red-color pointer"></i></td>
                    </tr>
                    <tr class="pointer" v-for="(todo, index) in filteredList" :key="index" >
                        <td class="border-l bg-gray" @click="openTodo(todo.id);"> {{ todo.title }}</td>
                        <td class="w60 text-center border-l"><i class="uil uil-edit-alt table-icons pointer" @click="open_todo_edit_modal(todo.id)"></i></td>
                        <td class="w60 text-center"><i class="uil uil-trash table-icons red-color pointer" @click="removeTodo(todo.id)"></i></td>
                    </tr>
                </tbody>
            </table>

            <!-- <div class="add-new-list-mobile">
                <div class="mobile-app-input-add btn-w100" @click="modal = !modal">افزودن فهرست جدید</div>
            </div> -->

        </slot>

        <!-- صفحه وظایف -->
        <slot v-if="!listShow && sidebarOptions != 'options' && sidebarOptions != 'edit'">

            <slot v-if="todoStarList.length && activeTodo == 0">
                <div v-if="todoStarList.length && search2" class="mobile-search-box">
                    <v-text-field class="mobile-app-input mobile-app-input-full" label="جستجو در وظیفه‌ها" id="newFavorite" v-model="todoFilterInput" @keyup="filterTodo"></v-text-field>
                </div>

                <div v-if="todoStarList.length == 0" class="noWidget noContent">
                    <div>
                        <i class="uil uil-clipboard-notes noContent-icon" aria-hidden="true"></i>
                        <p class="noContent-title">وظیفه‌ای برای نمایش وجود ندارد</p>
                    </div>
                </div>

                <table v-if="todoStarList.length" :class="'widget-table widget-table-lg ' + (showChecked ? 'checked-show' : '')">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>عنوان</th>
                            <th class="w60 text-center">منتخب</th>
                            <th class="w60 text-center">ویرایش</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="todoItem in todoStarList">
                            <tr class="pointer" v-if="!todoItem.hide" :key="todoItem.id" :class="{'checked': todoItem.checked}" >
                                <td class="w60 text-center border-l" @click="checkTodoItem(todoItem.id)">
                                    <input class="option-input checkbox todo-check" type="checkbox" :checked="todoItem.checked">
                                </td>
                                <td class="border-l bg-gray t-r-title" @click="triggerTodoPanel(todoItem.id);"> {{ todoItem.title }}</td>
                                <td class="w60 text-center border-l" @click="starTodoItem(todoItem.id)" ><i :class="'uil uil-star table-icons pointer ' + (todoItem.star ? 'active-star' : '')"></i></td>
                                <td class="w60 text-center" @click="triggerTodoPanel(todoItem.id)"><i class="uil uil-edit-alt table-icons pointer"></i></td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </slot>

            <slot v-else>

                <div v-if="activeTodo.items.length && search2" class="mobile-search-box">
                    <v-text-field class="mobile-app-input mobile-app-input-full" label="جستجو در وظیفه‌ها" id="newFavorite" v-model="todoFilterInput" @keyup="filterTodo"></v-text-field>
                </div>

                <div v-if="activeTodo.items.length == 0" class="noWidget noContent">
                    <div>
                        <i class="uil uil-clipboard-notes noContent-icon" aria-hidden="true"></i>
                        <p class="noContent-title">وظیفه‌ای برای نمایش وجود ندارد</p>
                        <div class="tgju-btn  m-top tgju-btn-primary" @click="modal = !modal"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد وظیفه‌</div>
                    </div>
                </div>

                <table v-if="activeTodo.items.length" :class="'widget-table widget-table-lg ' + (showChecked ? 'checked-show' : '')">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>عنوان</th>
                            <th class="w60 text-center">منتخب</th>
                            <th class="w60 text-center">ویرایش</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="todoItem in activeTodo.items">
                            <tr class="pointer" v-if="!todoItem.hide" :key="todoItem.id" :class="{'checked': todoItem.checked}" >
                                <td class="w60 text-center border-l" @click="checkTodoItem(todoItem.id)">
                                    <input class="option-input checkbox todo-check" type="checkbox" :checked="todoItem.checked">
                                </td>
                                <td class="border-l bg-gray t-r-title" @click="triggerTodoPanel(todoItem.id);"> {{ todoItem.title }}</td>
                                <td class="w60 text-center border-l" @click="starTodoItem(todoItem.id)" ><i :class="'uil uil-star table-icons pointer ' + (todoItem.star ? 'active-star' : '')"></i></td>
                                <td class="w60 text-center" @click="triggerTodoPanel(todoItem.id)"><i class="uil uil-edit-alt table-icons pointer"></i></td>
                            </tr>
                        </template>
                    </tbody>
                </table>

                <!-- <div class="add-new-list-mobile">
                    <div class="mobile-app-input-add btn-w100 green-input-add" @click="modal = !modal">افزودن وظیفه‌ جدید</div>
                </div> -->

            </slot>

        </slot>

        <!-- صفحه ویرایش -->
        <slot v-if="!listShow && sidebarOptions != 'options' && sidebarOptions == 'edit'">

            <v-text-field class="mobile-app-input" style="margin-bottom: 7px;" label="عنوان وظیفه" v-model="activeTodoItem.title" @blur="saveTodoItem()"></v-text-field>

            <date-picker class="mobile-app-input" v-model="activeTodoItem.date" placeholder="افزودن تاریخ" appendTo="body" :clearable="true" @change="saveTodoItem()"></date-picker>
            
            <div class="list-col l-title position-relative" style="display: block !important;height: auto;padding: 0 20px;">
                <input class="mobile-app-input mobile-app-input-file" type="file" ref="todo_attachments" @change="uploadAttachment($event.target.files)">
            </div>
            <div class="list-row atachments" style="padding: 0 20px 13px;" v-if="activeTodoItem.attachments.length">
                <div v-for="attachment in activeTodoItem.attachments" :key="attachment.id">
                    <span class="uil uil-times remove-row" @click="removeAttachment(attachment.id)"></span>
                    <span class="badge badge-secondary atachments-items pointer" @click="downloadAttachment(attachment.id)">
                        {{ attachment.title }}
                    </span>
                </div>
            </div>

            <v-textarea class="mobile-app-input" label="یادداشت" id="bio" v-model="activeTodoItem.note" :min-height="30" :max-height="350" @blur="saveTodoItem()"></v-textarea>

        </slot>

        <!-- صفحه تنظیمات -->
        <slot v-if="!listShow && sidebarOptions == 'options' && sidebarOptions != 'edit'">

            <table class="widget-table widget-table-lg" v-if="editable && wrapper != 'dashboard'">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>عنوان</th>
                    </tr>
                </thead>
                <tbody>
                    <template>
                        <tr class="pointer" >
                            <td class="w60 text-center border-l">
                                <input class="option-input checkbox todo-check" id="customControlValidation1" type="checkbox" v-model="showChecked">
                            </td>
                            <td class="border-l bg-gray">نمایش موارد انجام شده</td>
                        </tr>
                    </template>
                </tbody>
            </table>

        </slot>
        
        <v-overlay class="modal-overlay" :value="modal" @click="modal = false"></v-overlay>
        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="modal">
                    <v-sheet>
                        <div v-if="listShow" class="add-new-list-mobile in-modal">
                            <v-text-field class="mobile-app-input" label="عنوان فهرست" id="newTodo" v-model="newTodo" @keyup.enter="addTodo()"></v-text-field>
                            <div class="mobile-app-input-add" @click="addTodo()">افزودن فهرست</div>
                        </div>
                        <div v-if="!listShow" class="add-new-list-mobile in-modal">
                            <v-text-field class="mobile-app-input" label="عنوان وظیفه‌" id="newTodoItem" v-model="newTodoItem" @keyup.enter="addTodoItem()"></v-text-field>
                            <div class="mobile-app-input-add green-input-add" @click="addTodoItem()">افزودن وظیفه‌</div>
                        </div>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </slot>

</div>
</template>

<style lang="scss">
    .vpd-main {
        padding: 0 !important;

        label {
            display: none;
        }

        .vpd-input-group {
            padding: 0 !important;
            padding-right: 11px !important;

            .vpd-clear-btn {
                top: 4px;
            }
        }

        .Datepicker {
            padding: 0 !important;
        }
    }

    .widget-min-box-content {
        .l-title {
            display: flex;
        }

        .edit-todo {
            font-size: 14px;
            cursor: pointer;
            margin-right: -3px;
        }
    }
</style>

<script>
// این کامپوننت اجزای وظایف روزانه در خود جای میدهد
// import draggable from 'vuedraggable'
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize);

export default {
    name: 'TodoMobile',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {
        // draggable
    },
    data() {
        return {
            modal: false,
            listShow: true,
            sidebarOptions: 'list',
            searchQuery: '',
            todos: [],
            activeTodos: [],
            activeTodo: null,
            activeTodoItem: null,
            todoStarList: [],
            todoStarShow: false,
            newTodo: '',
            newTodoItem: '',
            todoFilterInput: '',
            todoListFilterInput: '',
            todoPanelOpen: false,
            showChecked: false,
            todoPanelIndex: null,
            responsive: {
                rightSection: true,
                leftSection: false,
                leftPanel: false,
            },
            widgetLoad: true,
            addEditLoading: false,
            addEditTodoLoading: false,
            search:false,
            search2:false
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                // تزریق داده های ورود به کامپوننت
                this.loadData();
            }
        }, { immediate: true });

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    computed: {
        // فیتلر سازی لیست های وظایف
        filteredList() {
            return this.todos.filter(todo => {
                return todo.title.toLowerCase().includes(this.searchQuery.toLowerCase())
            })
        },
    },
    methods: {
        get_modal(name){
            if(name == 'help'){
                this.open_help_modal();
            }else if(name == 'search'){
                this.search = !this.search;
            }else if(name == 'search2'){
                this.search2 = !this.search2;
            }
        },
        // این تابع وظیفه تزریق داده های ورودی به کامپوننت را دارد	
        loadData() {
            this.todos = this.data.todos;

            if (this.todos.length) {
                this.activeTodo = this.todos[0];
            }
            this.widgetLoad = false;
        },
        // این متد جهت مشاهده و فیلتر سازی ستاره دار ها می باشد
        showStarsTodo() {
            this.todoStarList = [];
            var elm = [];
            for (let i = 0; i < this.todos.length; i++) {
                if (this.todos[i].items.length > 0) {
                    for (let j = 0; j < this.todos[i].items.length; j++) {
                        if (this.todos[i].items[j].star == true) {
                            elm.push(this.todos[i].items[j]);
                        }
                    }
                }
            }

            this.todoStarList = elm;
            this.todoStarShow = true;
            this.activeTodo = 0;
            this.listShow = false;
        },
        // این متد وظیفه ی حذف یک وظیفه از لیست وظیفه ها را دارد
        removeTodo(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let index = this.$helpers.getIndex(this.todos, id);

                    this.todos.splice(index, 1);

                    let next_index = index - 1;
                    if (next_index < 0)
                        next_index = 0;

                    if (this.todos.length > 0) {
                        this.openTodo(this.todos[next_index].id);
                    } else {
                        this.activeTodo = null;
                        this.activeTodoItem = null;
                    }
                    // ارسال درخواست به وب سرویس	
                    this.$helpers.makeRequest('DELETE', '/todo/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            // this.updateSource();
                            this.$swal.fire({
                                icon: "success",
                                title: "فهرست با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف فهرست رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // این متد وظیفه این را دارد پس از کلیک روی وظیفه مورد نظر آیتم های آن را لود میکند
        openTodo(id) {
            this.todoStarShow = false;

            let index = this.$helpers.getIndex(this.todos, id);

            this.activeTodo = this.todos[index];

            this.todoPanelOpen = false;

            this.listShow = false;
        },
        // این متد وظیفه این را دارد که یک وظیفه جدید را اضافه میکند
        addTodo() {
            if (!this.newTodo) {
                this.$swal.fire({
                    icon: "error",
                    title: "لطفا عنوان فهرست را وارد نمایید",
                    showConfirmButton: !1,
                    timer: 2000
                });
            }
            if (!this.newTodo) return;
            this.addEditLoading = true;
            let id = 'id-' + new Date().getTime();

            let index = this.todos.push({
                id,
                title: this.newTodo,
                items: []
            });

            let api_data = {
                title: this.newTodo,
                items: null,
            };

            this.$helpers.makeRequest('POST', '/todo/save', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let inserted_id = api_response.data.response.id;

                    this.todos.forEach((todo, index) => {
                        if (todo.id == id) {
                            todo.id = inserted_id;
                        }
                    });
                    this.$swal.fire({
                        icon: "success",
                        title: "فهرست با موفقیت ایجاد شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ایجاد فهرست رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
                this.modal = false;
                this.addEditLoading = false;
            });

            this.newTodo = '';
            // this.openTodo(id);
        },
        // این متد برای ستاره دار کردن یکی از آیتم های وظیفه می باشد
        starTodoItem(id) {
            let index = this.$helpers.getIndex(this.activeTodo.items, id);

            this.activeTodo['items'][index]['star'] = !this.activeTodo['items'][index]['star'];

            this.saveTodoItem();
        },
        // این متد برای تیک دار کردن یکی از آیتم های وظیفه می باشد 
        checkTodoItem(id) {
            let index = this.$helpers.getIndex(this.activeTodo.items, id);

            this.activeTodo['items'][index]['checked'] = !this.activeTodo['items'][index]['checked'];

            this.saveTodoItem();
        },
        // این متد وظیفه اپلود فایل اتچ شده به آیتم مورد نظر از وظیفه انتخاب شده
        uploadAttachment(fileList) {
            let attachment = [];
            if (fileList.length) {
                attachment = fileList[0];
            } else {
                attachment = null;
            }

            if (attachment) {
                let api_data = {
                    attachment
                };

                this.$helpers.makeRequest('POST', '/todo/upload', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        let response = api_response.data.response;
                        this.activeTodoItem.attachments.push(response);
                        this.saveTodoItem('uploadAttachment');
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در بروزرسانی فایل پیوست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                });
            }
        },
        // این متد برای حذف فایل آپلود شده
        removeAttachment(attach_id) {
            let api_data = {
                todo_id: this.activeTodo.id,
                todo_item_id: this.activeTodoItem.id,
                attach_id: attach_id,
                type: 'remove',
            };

            this.$helpers.makeRequest('GET', `/todo/download-or-remove`, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let index = this.$helpers.getIndex(this.activeTodoItem.attachments, attach_id);
                    this.activeTodoItem.attachments.splice(index, 1);
                    this.saveTodoItem('removeAttachment');
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در حذف فایل پیوست رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
            });
        },
        // این متد جهت دانلود فایل آپلود شده می باشد
        downloadAttachment(attach_id) {
            let api_data = {
                todo_id: this.activeTodo.id,
                todo_item_id: this.activeTodoItem.id,
                attach_id: attach_id,
                type: 'download',
            };

            this.$helpers.makeRequest('GET', `/todo/download-or-remove`, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    window.open(api_response.data.link, '_blank');
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در دانلود فایل پیوست رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
            });
        },
        // این متد برای فیلتر کردن لیست وظایف و ستاره داره بودن آنها استفاده میشود
        filterTodo() {
            if (!this.todoStarShow) {
                this.activeTodo['items'].forEach((todoItem, index) => {
                    if (this.todoFilterInput.trim() == '') {
                        this.activeTodo['items'][index]['hide'] = false;
                    } else if (todoItem.title.indexOf(this.todoFilterInput.trim()) > -1) {
                        this.activeTodo['items'][index]['hide'] = false;
                    } else {
                        this.activeTodo['items'][index]['hide'] = true;
                    }
                });
            } else {
                this.todoStarList.forEach((todoItem, index) => {
                    if (this.todoFilterInput.trim() == '') {
                        this.todoStarList[index]['hide'] = false;
                    } else if (todoItem.title.indexOf(this.todoFilterInput.trim()) > -1) {
                        this.todoStarList[index]['hide'] = false;
                    } else {
                        this.todoStarList[index]['hide'] = true;
                    }
                });
            }
        },
        triggerTodoPanel(id) {
            let index = this.$helpers.getIndex(this.activeTodo.items, id);

            if (index != this.todoPanelIndex) {
                this.todoPanelOpen = true;
            } else {
                this.todoPanelOpen = !this.todoPanelOpen;
            }

            this.todoPanelIndex = index;
            this.activeTodoItem = this.activeTodo['items'][index];
            this.showSidebar('edit');
            this.listShow = false;
        },
        // این متد برای حذف آیتم های لیست وظایف استفاده میشود
        removeTodoItem(id) {
            let index = this.$helpers.getIndex(this.activeTodo.items, id);

            this.todoPanelIndex = null;
            this.todoPanelOpen = false;
            this.activeTodo.items.splice(index, 1);

            this.saveTodoItem('removeTodo');
            this.listShow = false;
            this.sidebarOptions = false;
        },
        // این متد وظیفه هندل کردن افزودن آیتم به لیست وظایف را دارا می باشد
        addTodoItem() {
            if (!this.newTodoItem) {
                this.$swal.fire({
                    icon: "error",
                    title: "لطفا عنوان وظیفه را وارد نمایید",
                    showConfirmButton: !1,
                    timer: 2000
                });
            }
            if (!this.newTodoItem) {
                return;
            }

            if (this.newTodoItem) {
                this.activeTodo.items.push({
                    id: 'id-' + new Date().getTime(),
                    title: this.newTodoItem,
                    note: '',
                    date: null,
                    star: false,
                    attachments: [],
                    checked: false,
                    hide: false
                });

                this.newTodoItem = '';
                this.saveTodoItem('addTodo');
            }
        },
        // این متد درخواست ذخیره سازی آیتم جدید لیست وظایف را به وب سرویس ارسال میکند
        saveTodoItem(action = null) {
            let api_data = {
                items: JSON.stringify(this.$helpers.unbindObject(this.activeTodo.items)),
            };

            if (action == 'addTodo') {
                this.addEditTodoLoading = true;
            }

            this.$helpers.makeRequest('POST', '/todo/item-save/' + this.activeTodo.id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (action == 'addList') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فهرست با موفقیت ایجاد شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در ایجاد فهرست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'removeList') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فهرست با موفقیت حذف شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در حذف فهرست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'updateList') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فهرست با موفقیت بروزرسانی شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در بروزرسانی فهرست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'addTodo') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "وظیفه با موفقیت ایجاد شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                        this.addEditTodoLoading = false;
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در ایجاد وظیفه رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                        this.addEditTodoLoading = false;
                    }
                } else if (action == 'removeTodo') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "وظیفه با موفقیت حذف شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در حذف وظیفه رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'updateTodo') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "وظیفه با موفقیت بروزرسانی شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در بروزرسانی وظیفه رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'addAttachment') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فایل پیوست با موفقیت افزوده شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در افزوده فایل پیوست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'removeAttachment') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فایل پیوست با موفقیت حذف شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در حذف فایل پیوست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'updateAttachment') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فایل پیوست با موفقیت بروزرسانی شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در بروزرسانی فایل پیوست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                }
            });
        },
        // این متد جهت هندل سورت کردن و ترتیب قرار گیری ایتم های لیست وظایف استفاده میشود
        saveTodosSort() {
            let sort = [];
            this.todos.forEach(todo => {
                sort.push(todo.id);
            });

            let api_data = {
                sort: JSON.stringify(sort),
            };

            this.$helpers.makeRequest('POST', '/todo/sort', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'ذخیره',
                        message: 'مرتب سازی با موفقیت ذخیره شد',
                    });
                } else {
                    Vue.prototype.$toast.warn({
                        title: 'ذخیره',
                        message: 'مشکلی در مرتب سازی رخ داده است',
                    });
                }
            });
        },
        // این متد وظیفه هندل کردن مودال تنظیمات وظایف در ویجت داشبورد است
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/Todo.vue',
                config: {
                    data: {
                        title: this.widget.title,
                        showChecked: this.widget.data.showChecked,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);
            // کال بک فانکشن
            this.$root.$on(callbackEvent, (settings) => {
                this.widget.data.showChecked = settings.showChecked;
                this.widget.title = settings.title;

                let widget = this.$helpers.unbindObject(this.widget);

                this.$helpers.saveWidget(widget);
            });
        },
        // این متد برای ویرایش عنوان وظیفه استفاده میشود // هندل کردن مودال و کال بک فاکنش
        open_todo_edit_modal(id = 0) {
            if (id > 0) {
                let index = this.$helpers.getIndex(this.todos, id);
                this.activeTodo = this.todos[index];
            }

            let callbackEvent = this.$router.currentRoute.name + '_EditTodos_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'TodoEdit.vue',
                config: {
                    title: 'ویرایش فهرست',
                    icon: 'uil uil-edit-alt with-icon',
                    smallModal: true,
                    data: {
                        todo: this.$helpers.unbindObject(this.activeTodo),
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'editTodo') {
                    this.activeTodo.title = response.data.todo.title;

                    let api_data = {
                        title: this.activeTodo.title,
                    };

                    this.$helpers.makeRequest('POST', '/todo/edit/' + this.activeTodo.id, api_data).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            // this.updateSource();
                            this.$swal.fire({
                                icon: "success",
                                title: "فهرست با موفقیت بروزرسانی شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در بروزرسانی فهرست رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        makeResponsive(mode) {
            if (mode == 1) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = true;
                this.responsive.leftPanel = false;
            } else if (mode == 2) {
                this.responsive.rightSection = true;
                this.responsive.leftSection = false;
                this.responsive.leftPanel = false;
            } else if (mode == 3) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = false;
                this.responsive.leftPanel = true;
            }
        },
        showSidebar(e) {
            if (this.sidebarOptions == e) {
                this.listShow = false;
                this.sidebarOptions = null;
            } else {
                this.sidebarOptions = e;
                this.listShow = true;
            }
        },
        showSidebar2(e) {
            if (this.sidebarOptions == e) {
                this.listShow = true;
                this.sidebarOptions = null;
            } else {
                this.sidebarOptions = e;
                this.listShow = false;
            }
        },
        hideSidebar() {
            this.listShow = false;
        },
        hideSidebar2() {
            this.listShow = false;
            this.sidebarOptions = null;
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Todo.vue',
                title: this.widget.title,
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
